import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Empty from './Empty'
import NewProject from './NewProject';
import NewSection from './NewSection';
import EditProject from './EditProject';
import ConfirmDelete from './ConfirmDelete';
import ProjectTable from './ProjectTable';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useParams } from 'react-router-dom';
import SectionTemplatesTable from './SectionTemplatesTable';
import ViewSectionTemplate from './ViewSectionTemplate';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export default function Dashboard() {
    const { id } = useParams();

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [projects, setProjects] = useState([])
    const [templates, setTemplates] = useState([])
    const [currentProjectId, setCurrentProjectId] = useState(null)
    const [newProjectOpen, setNewProjectOpen] = useState(false)
    const [newTemplateOpen, setNewTemplateOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState('Active')
    const [viewSectionTemplates, setViewSectionTemplates] = useState(false)
    const [viewSpecificSectionTemplate, setViewSpecificSectionTemplate] = useState(false)
    const [specificSectionTemplate, setSpecificSectionTemplate] = useState(null)
    const [editProjectOpen, setEditProjectOpen] = useState(false)
    const [editProjectId, setEditProjectId] = useState(null)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [potentialDeleteId, setPotentialDeleteId] = useState(null)

    const options = [
        { name: 'Active', onClick: () => { setStatus('Active'); setViewSectionTemplates(false) } },
        { name: 'Archived', onClick: () => { setStatus('Archived'); setViewSectionTemplates(false) } },
    ]
    const userNavigation = [
        { name: 'New Project', action: () => setNewProjectOpen(true) },
        { name: 'New Section', action: () => setNewTemplateOpen(true) },
    ]

    const refreshProjects = async () => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_BACKEND}/api/photos/projects`).then((response) => {
            setProjects((response.data));
            setIsLoading(false);
        }).catch((error) => {
            console.error('Error fetching data:', error);
            setIsLoading(false)
        });
    }
    useEffect(() => {
        refreshProjects();
        refreshTemplates();

    }, []);

    useEffect(() => {
        if (id) {
            setCurrentProjectId(id);
        }
    }, [id])

    useEffect(() => {
        if (projects.length > 0 && !currentProjectId && !id) {
            setCurrentProjectId(projects[0].project_id);
        }
    }, [projects])


    const refreshTemplates = () => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/photos/sections`).then((response) => {
            setTemplates((response.data));
            if (response.data.length === 0) {
                setNewTemplateOpen(true);
            }
            // setIsLoading(false); // Update loading state after fetching data
        }).catch((error) => {
            console.error('Error fetching data:', error);
            // setIsLoading(false); // Ensure loading state is updated even on error
        });
    }

    const refreshOnCreateProject = (newId) => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/photos/projects`).then((response) => {
            setProjects((response.data));
            // setIsLoading(false); // Update loading state after fetching data
        }).catch((error) => {
            console.error('Error fetching data:', error);
            // setIsLoading(false); // Ensure loading state is updated even on error
        });
        setCurrentProjectId(newId);
    }

    const createNewTemplateOnNewProject = () => {
        setNewProjectOpen(false);
        setNewTemplateOpen(true);

    }

    const handleEditProject = (id) => {
        setEditProjectId(id);
        setEditProjectOpen(true);
    }

    const showConfirmDelete = (val, id) => {
        setConfirmDeleteOpen(val);
        setPotentialDeleteId(id);
    }

    return (
        <>
            <NewProject
                open={newProjectOpen}
                setOpen={setNewProjectOpen}
                sections={templates}
                refresh={(id) => refreshOnCreateProject(id)}
                openCreateSection={createNewTemplateOnNewProject}
            />
            <NewSection
                open={newTemplateOpen}
                setOpen={setNewTemplateOpen}
                refreshSections={refreshTemplates}
            />
            <ViewSectionTemplate
                open={viewSpecificSectionTemplate}
                setOpen={setViewSpecificSectionTemplate}
                refreshSections={refreshTemplates}
                id={specificSectionTemplate}
            />
            <EditProject
                open={editProjectOpen}
                setOpen={setEditProjectOpen}
                sections={templates}
                refresh={(id) => refreshOnCreateProject(id)}
                openCreateSection={createNewTemplateOnNewProject}
                projectId={editProjectId}
            />
            <ConfirmDelete
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                projectId={potentialDeleteId}
                refreshProjects={refreshProjects}
            />
            
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                        <div className="flex h-16 shrink-0 items-center justify-start">
                                            <span className="text-lg font-bold text-black">1EarthSolar <span className="text-orange-500">OS</span></span>
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                                <li>
                                                    <div className="text-md font-semibold leading-6 text-gray-400">Projects</div>
                                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                        {options.map((option) => (
                                                            <li key={option.name}>
                                                                <a
                                                                    onClick={option.onClick}
                                                                    className={classNames(
                                                                        option.name === status
                                                                            ? 'bg-gray-100 text-indigo-600 font-semibold'
                                                                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 font-medium',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                    )}
                                                                >
                                                                    <span>{option.name}</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                        {projects.length === 0 && (
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-light"
                                                                >
                                                                    <span className="truncate">No projects</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                        <div className="flex h-16 shrink-0 items-center justify-start">
                            <span className="text-lg font-bold text-black">1EarthSolar <span className="text-orange-500">OS</span></span>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <div className="text-md font-semibold leading-6 text-gray-400">Projects</div>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                        {options.map((option) => (
                                            <li key={option.name}>
                                                <a
                                                    onClick={option.onClick}
                                                    className={classNames(
                                                        option.name === status
                                                            ? 'bg-gray-100 text-indigo-600 font-semibold'
                                                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 font-medium',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                                                    )}
                                                >
                                                    <span>{option.name}</span>
                                                </a>
                                            </li>
                                        ))}
                                        {projects.length === 0 && (
                                            <li>
                                                <a
                                                    href="#"
                                                    className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-light"
                                                >
                                                    <span className="truncate">No projects</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="text-md mt-3 font-semibold leading-6 text-gray-400">Section Templates</div>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                        <li key='view'>
                                            <a
                                                onClick={() => { setStatus(''); setViewSectionTemplates(true) }}
                                                className={classNames(
                                                    viewSectionTemplates
                                                        ? 'bg-gray-100 text-indigo-600 font-semibold'
                                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 font-medium',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                                                )}                                            >
                                                <span>View & Edit</span>
                                            </a>
                                        </li>
                                        {templates.length === 0 && (
                                            <li>
                                                <a
                                                    href="#"
                                                    className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-light"
                                                >
                                                    <span className="truncate">No templates</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="lg:pl-72">
                    <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 justify-between">
                        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <div className="flex items-center justify-end w-full">
                            <div className="flex  items-center justify-end gap-x-4 lg:gap-x-6 ml-auto">
                                {/* Menu dropdown */}
                                <Menu as="div" className="relative ml-auto">
                                    <Menu.Button className="-m-1.5 flex bg-indigo-600 rounded-md items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>

                                        <span className="flex items-center">
                                            <span className="ml-2 text-sm font-semibold leading-6 text-white lg:hidden" aria-hidden="true">
                                                +
                                            </span>
                                            <span className="hidden ml-2 text-sm font-semibold leading-6 text-white lg:flex lg:items-center" aria-hidden="true">
                                                + New
                                            </span>
                                            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-200" aria-hidden="true" />
                                        </span>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            {userNavigation?.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={item.action}
                                                            className={classNames(
                                                                active ? 'bg-gray-50' : '',
                                                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                                            )}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="flex items-center justify-center p-6">
                            <Skeleton count={5} />
                        </div>
                    ) : (<>
                        {projects.length === 0 ? (
                            <main className="flex items-center justify-center py-10" style={{ minHeight: 'calc(100vh - 64px - 80px)' }}>
                                <div className="w-full px-4 sm:px-6 lg:px-8">
                                    <Empty showCreateProject={() => setNewProjectOpen(true)} />
                                </div>
                            </main>
                        )
                            : (<main className="flex items-center justify-center" >
                                <div className="w-full px-4 sm:px-6 lg:px-8">
                                    {viewSectionTemplates ? (
                                        <SectionTemplatesTable
                                            templates={templates}
                                            refreshTemplates={refreshTemplates}
                                            openPopup={(show, id) => { setViewSpecificSectionTemplate(show); setSpecificSectionTemplate(id) }}

                                        />
                                    ) : (<ProjectTable
                                        projects={projects}
                                        refreshProjects={refreshProjects}
                                        status={status}
                                        handleEditProject={handleEditProject}
                                        showConfirmDelete={showConfirmDelete}
                                    />)}

                                </div>
                            </main>)}
                    </>)}


                </div>
            </div>
        </>
    )
}
