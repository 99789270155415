import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/24/outline'; // Import the TrashIcon

export default function SectionTemplatesTable({ templates, refreshTemplates, openPopup }) {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const navigate = useNavigate();

    const handleOutsideClick = (e) => {
        if (!e.target.closest('.dropdown')) {
            setDropdownOpen(null);
        }
    };

    const deleteSection = async (sectionId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/photos/sections/${sectionId}`);
            if (response.status === 200) {
                refreshTemplates();
                alert('Section deleted successfully');
            } else {
                throw new Error('Failed to delete the section');
            }
        } catch (error) {
            console.error('Error deleting section:', error);
            alert('Error deleting section');
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {templates.map((template) => (
                                        <tr
                                            key={template.section_id}
                                            className="hover:bg-gray-50"
                                        >
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {template.name}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <div className="dropdown relative inline-flex items-center">
                                                    <TrashIcon className="w-5 h-5 text-red-600 mr-4 cursor-pointer"
                                                        onClick={() => deleteSection(template.section_id)} />
                                                    <button
                                                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer hover:underline"
                                                        onClick={() => openPopup(true, template.section_id)}
                                                    >
                                                        Edit<span className="sr-only">, {template.name}</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
