import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import axios from 'axios';
const cum = ["hi", "hello", "bye"]

export default function NewSection({ open, setOpen, refreshSections }) {
    const [sectionName, setSectionName] = useState('');
    const [rows, setRows] = useState([]);
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(false);

    const items = [
        { name: '+ Add Radio Button', href: '#' },
        { name: '+ Add Dropdown', href: '#' },
    ]

    const addRow = () => {
        console.log('Adding row');
        setRows([...rows, { name: '', required: false, description: '', checklistItems: [] }]);
    };

    const addCollection = () => {
        setCollections([...collections, { name: '', description: '', rows: [{ name: '', required: false, description: '', checklistItems: [] }] }]);
    };

    const addRowToCollection = (collectionIndex) => {
        const newCollections = [...collections];
        newCollections[collectionIndex].rows.push({ name: '', required: false, description: '', checklistItems: [] });
        setCollections(newCollections);
    };

    const updateCollectionName = (index, name) => {
        const newCollections = [...collections];
        newCollections[index].name = name;
        setCollections(newCollections);
    };

    const updateCollectionDescription = (index, description) => {
        const newCollections = [...collections];
        newCollections[index].description = description;
        setCollections(newCollections);
    };

    const updateRowInCollection = (collectionIndex, rowIndex, updatedRow) => {
        const newCollections = [...collections];
        const collection = newCollections[collectionIndex];
        collection.rows[rowIndex] = { ...collection.rows[rowIndex], ...updatedRow };
        setCollections(newCollections);
    };

    const deleteCollection = (index) => {
        const newCollections = collections.filter((_, i) => i !== index);
        setCollections(newCollections);
    };

    const updateRowName = (index, name) => {
        const newRows = [...rows];
        newRows[index].name = name;
        setRows(newRows);
    };

    const updateRowDescription = (index, description) => {
        const newRows = [...rows];
        newRows[index].description = description;
        setRows(newRows);
    };

    const updateRowRequired = (index, required) => {
        const newRows = [...rows];
        newRows[index].required = required;
        setRows(newRows);
    };

    const deleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const canCreateSection = () => {
        return sectionName.trim() !== '' && (rows.some(row => row.name.trim() !== '') || collections.some(collection => collection.name.trim() !== ''));
    };

    const createSection = () => {
        setLoading(true);
        const newSection = {
            name: sectionName,
            rows: rows.filter(row => row.name !== ''),
            collections: collections
                .filter(collection => collection.name.trim() !== '')
                .map(collection => ({
                    name: collection.name,
                    description: collection.description,
                    rows: collection.rows.filter(row => row.name !== '')
                }))
        };
        axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/sections`, newSection).then(() => {
            refreshSections();
            setOpen(false);
            setSectionName('');
            setRows([{ name: '', required: false }]);
            setCollections([{ name: '', description: '', rows: [{ name: '', required: false }] }]);
            setLoading(false);
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        console.log(result);
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index
        );
        console.log(items);

        setRows(items);
    }

    const addChecklistItem = (collectionIndex, rowIndex, item) => {
        const newCollections = [...collections];
        const newRows = [...rows];

        if(collectionIndex === null)
        {
            if(!newRows[rowIndex].checklistItems)
            {
                newRows[rowIndex].checklistItems = [];
            }
            newRows[rowIndex].checklistItems.push(item);
            setRows(newRows);
            console.log(rows);
            return;
        }

        if (!newCollections[collectionIndex].rows[rowIndex].checklistItems) {
            newCollections[collectionIndex].rows[rowIndex].checklistItems = [];
        }
        newCollections[collectionIndex].rows[rowIndex].checklistItems.push(item);
        setCollections(newCollections);
        console.log(collections);
    };

    const removeChecklistItem = (collectionIndex, rowIndex, itemIndex) => {
        const newCollections = [...collections];
        const newRows = [...rows];

        if(collectionIndex === null)
        {
            newRows[rowIndex].checklistItems.splice(itemIndex, 1);
            setRows(newRows);
            return;
        }
        else
        {
            newCollections[collectionIndex].rows[rowIndex].checklistItems.splice(itemIndex, 1);
            setCollections(newCollections);
            return;
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10 overflow-y-auto" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-4xl transform rounded-lg bg-white p-6 text-left shadow-xl transition-all overflow-auto">
                                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                    Create New Section
                                </Dialog.Title>
                                <div className=' max-h-[70vh] overflow-y-auto'>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            placeholder="Section Name"
                                            value={sectionName}
                                            onChange={(e) => setSectionName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-2 space-x-4">
                                        <button
                                            type="button"
                                            className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                            onClick={addRow}
                                        >
                                            + Add Row
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                            onClick={addCollection}
                                        >
                                            + Add Collection
                                        </button>
                                        <div className="inline-flex rounded-md shadow-sm">
                                            <button
                                                type="button"
                                                className="relative inline-flex items-center rounded-l-md bg-gray-600 px-3 py-2 text-sm font-medium text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-700 focus:z-10"
                                            >
                                                + Add text field
                                            </button>
                                            <Menu as="div" className=" z-50 -ml-px block">
                                                <Menu.Button className="relative inline-flex items-center rounded-r-md bg-gray-600 px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-700 focus:z-50">
                                                    <span className="sr-only">Open options</span>
                                                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-36 z-50  mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                                    >
                                                        <div className="py-1 z-50">
                                                            {items.map((item) => (
                                                                <Menu.Item key={item.name}>
                                                                    <a
                                                                        href={item.href}
                                                                        className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                </Menu.Item>
                                                            ))}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex-container">
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                        className="flex flex-col space-y-2"
                                                    >
                                                        {rows.map((row, index) => (
                                                            <Draggable key={index} index={index} draggableId={`${index}`}>
                                                                {(_provided, _snapshot) =>
                                                                    <div
                                                                        ref={_provided.innerRef}
                                                                        {..._provided.draggableProps}
                                                                        {..._provided.dragHandleProps}
                                                                        className='flex-item bg-gray-50 rounded-lg px-3 py-1.5 hover:bg-gray-200'
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <input
                                                                                type="text"
                                                                                className="block w-full  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                placeholder={`Row ${index + 1} Name`}
                                                                                value={row.name}
                                                                                onChange={(e) => updateRowName(index, e.target.value)}
                                                                            />
                                                                            <div className="flex items-center ml-2">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={row.required}
                                                                                    onChange={(e) => updateRowRequired(index, e.target.checked)}
                                                                                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                                                />
                                                                                <label className="ml-2 text-sm text-gray-600" htmlFor={`required-${index}`}>Required</label>
                                                                            </div>
                                                                            <button
                                                                                onClick={() => deleteRow(index)}
                                                                                className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                                            >
                                                                                <TrashIcon className="h-4 w-4" />
                                                                            </button>
                                                                        </div>
                                                                        <textarea
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                            placeholder="Description"
                                                                            value={row.description}
                                                                            onChange={(e) => updateRowDescription(index, e.target.value)}
                                                                            rows="1"
                                                                        />
                                                                        <div className="flex flex-wrap items-center mt-2">
                                                                            {row.checklistItems && row.checklistItems.map((item, itemIndex) => (
                                                                                <div key={itemIndex} className="bg-gray-100 rounded-md px-3 py-1 m-1 flex items-center">
                                                                                    <span>{item.name}</span>
                                                                                    <button
                                                                                        onClick={() => removeChecklistItem(null, index, itemIndex)}
                                                                                        className="ml-2 text-gray-500 hover:text-red-500 focus:outline-none"
                                                                                    >
                                                                                        <XMarkIcon className="h-4 w-4" />
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                type="text"
                                                                                className="border border-gray-300 rounded-md px-2 py-1 m-1 text-sm"
                                                                                placeholder="Add Checklist Item +"
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                                        addChecklistItem(null, index, { name: e.target.value.trim() });
                                                                                        e.target.value = '';
                                                                                    }
                                                                                }}
                                                                                onBlur={(e) => {
                                                                                    if (e.target.value.trim() !== '') {
                                                                                        addChecklistItem(null, index, { name: e.target.value.trim() });
                                                                                        e.target.value = '';
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Draggable>
                                                        ))}
                                                    </div>
                                                )}

                                            </Droppable>
                                        </DragDropContext>

                                    </div>
                                    <div className="mt-4">
                                        {collections.map((collection, cIndex) => (
                                            <div key={cIndex} className="mt-4 p-4 border border-gray-300 rounded-md">
                                                <div className="flex justify-between">
                                                    <input
                                                        type="text"
                                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        placeholder={`Collection ${cIndex + 1} Name`}
                                                        value={collection.name}
                                                        onChange={(e) => updateCollectionName(cIndex, e.target.value)}
                                                    />
                                                    <button
                                                        onClick={() => deleteCollection(cIndex)}
                                                        className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                    >
                                                        <XMarkIcon className="h-4 w-4" />
                                                    </button>
                                                </div>
                                                <textarea
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Description"
                                                    value={collection.description}
                                                    onChange={(e) => updateCollectionDescription(cIndex, e.target.value)}
                                                    rows="1"
                                                />
                                                {collection.rows.map((row, rIndex) => (
                                                    <>
                                                        <div key={rIndex} className="flex items-center mt-2">
                                                            <input
                                                                type="text"
                                                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                placeholder={`Row ${rIndex + 1} Name`}
                                                                value={row.name}
                                                                onChange={(e) => updateRowInCollection(cIndex, rIndex, { name: e.target.value })}
                                                            />
                                                            <input
                                                                type="checkbox"
                                                                checked={row.required}
                                                                onChange={(e) => updateRowInCollection(cIndex, rIndex, { required: e.target.checked })}
                                                                className="ml-2 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                            />
                                                            <label className="ml-2 text-sm text-gray-600" htmlFor={`required-${cIndex}-${rIndex}`}>Required</label>
                                                            <button
                                                                onClick={() => {
                                                                    const newCollections = [...collections];
                                                                    newCollections[cIndex].rows.splice(rIndex, 1);
                                                                    setCollections(newCollections);
                                                                }}
                                                                className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                            >
                                                                <XMarkIcon className="h-4 w-4" />
                                                            </button>
                                                        </div>
                                                        <textarea
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            placeholder={`Row ${rIndex + 1} Description`}
                                                            value={row.description}
                                                            onChange={(e) => updateRowInCollection(cIndex, rIndex, { description: e.target.value })}
                                                            rows="1"
                                                        />
                                                        <div className="flex flex-wrap items-center mt-2">
                                                            {row.checklistItems && row.checklistItems.map((item, itemIndex) => (
                                                                <div key={itemIndex} className="bg-gray-100 rounded-md px-3 py-1 m-1 flex items-center">
                                                                    <span>{item.name}</span>
                                                                    <button
                                                                        onClick={() => removeChecklistItem(cIndex, rIndex, itemIndex)}
                                                                        className="ml-2 text-gray-500 hover:text-red-500 focus:outline-none"
                                                                    >
                                                                        <XMarkIcon className="h-4 w-4" />
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            <input
                                                                type="text"
                                                                className="border border-gray-300 rounded-md px-2 py-1 m-1 text-sm"
                                                                placeholder="Add Checklist Item +"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                        addChecklistItem(cIndex,rIndex, {name:e.target.value.trim()});
                                                                        e.target.value = '';
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (e.target.value.trim() !== '') {
                                                                        addChecklistItem(cIndex, rIndex, {name:e.target.value.trim()});
                                                                        e.target.value = '';
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                                    onClick={() => addRowToCollection(cIndex)}
                                                >
                                                    + Add Row to Collection
                                                </button>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm ${canCreateSection() ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-400 cursor-not-allowed'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                        onClick={createSection}
                                        disabled={!canCreateSection()}
                                    >
                                        {loading ? (
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full" role="status">
                                                <span className='visually-hidden sr-only'>Loading...</span>
                                            </div>
                                        ) : (
                                            'Create Section'
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
