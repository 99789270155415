import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

function LoginSuccess() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Function to get query params
        const searchParams = new URLSearchParams(location.search);
        const uid = searchParams.get('uid');

        if (uid) {
            Cookies.set('uid', uid, { expires: 7 }); // Set cookie with a 7 day expiry
            navigate('/'); // Redirect to the root path
        }
    }, [location, navigate]);

    return null; // No UI, just logic
}

export default LoginSuccess;