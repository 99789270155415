import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ProjectTable({ projects, refreshProjects, status = 'Active', handleEditProject, showConfirmDelete }) {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const navigate = useNavigate();

    const toggleDropdown = (projectId) => {
        setDropdownOpen(dropdownOpen === projectId ? null : projectId);
    };

    const handleOutsideClick = (e) => {
        if (!e.target.closest('.dropdown')) {
            setDropdownOpen(null);
        }
    };

    const handleArchiveProject = async (projectId) => {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/projects/archive`, { projectId: projectId, archived: true });
        if (response.status === 200) {
            refreshProjects();
        }
        setDropdownOpen(null);
    };

    const handleActivateProject = async (projectId) => {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/projects/archive`, { projectId: projectId, archived: false });
        if (response.status === 200) {
            refreshProjects();
        }
        setDropdownOpen(null);
    };


    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        let sortedProjects = [...projects];
        if (status === 'Active') {
            sortedProjects = sortedProjects.filter(project => !project.archived && !project.deleted);
            // Prioritize projects that are ready for closeout
            sortedProjects.sort((a, b) => b.ready_for_close - a.ready_for_close);
        } else if (status === 'Archived') {
            sortedProjects = sortedProjects.filter(project => project.archived && !project.deleted);
        } else if (status === 'Deleted') {
            sortedProjects = sortedProjects.filter(project => project.deleted);
        }
        setFilteredProjects(sortedProjects);
    }, [projects, status]);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Customer Address</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Install Date</th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"><span className="sr-only">Edit</span></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {filteredProjects.map((project) => (
                                        <tr key={project.project_id} className="cursor-pointer hover:bg-gray-50" onClick={() => navigate(`/day-of/${project.project_id}`)}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {project.name}
                                                {status === 'Active' && project.ready_for_close && (
                                                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                        Awaiting Closeout
                                                    </span>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.customer_address}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.scheduled_duration}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <div className="dropdown relative">
                                                    <button
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleDropdown(project.project_id)
                                                        }}
                                                    >
                                                        ...<span className="sr-only">, {project.name}</span>
                                                    </button>
                                                    {dropdownOpen === project.project_id && (
                                                        <div className={`absolute ${filteredProjects.indexOf(project) === filteredProjects.length - 1 ? 'bottom-1' : 'top-1'} right-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50`}>
                                                            {status === 'Active' ? (
                                                                <>
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleEditProject(project.project_id)
                                                                            setDropdownOpen(null);
                                                                        }}
                                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                                    >
                                                                        Edit Project
                                                                    </button>
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleArchiveProject(project.project_id)
                                                                        }}
                                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                                    >
                                                                        Archive Project
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleActivateProject(project.project_id)
                                                                        }}
                                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                                    >
                                                                        Activate Project
                                                                    </button>
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setDropdownOpen(null);
                                                                            // handleDeleteProject(project.project_id)
                                                                            showConfirmDelete(true, project.project_id);
                                                                        }}
                                                                        className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
                                                                    >
                                                                        Delete Project
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}