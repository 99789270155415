import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import DayOfView from './pages/DayOfView';
import LoginSuccess from './pages/LoginSuccess';
// Protected route component
const ProtectedRoute = ({ children }) => {
  const uid = Cookies.get('uid');
  if (!uid) {
    // If uid is not set, redirect to /login
    return <Navigate to="/login" replace />;
  }
  return children;
};

// Public route component
const PublicRoute = ({ children }) => {
  const uid = Cookies.get('uid');
  if (uid) {
    // If uid is set, redirect to /
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  return (
    <div className=''>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/:id'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='day-of/:id'
            element={
              <DayOfView />
            }
          />
          <Route
            path='/login'
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path='/success'
            element={
              <PublicRoute>
                <LoginSuccess />
              </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;