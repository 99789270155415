import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

export default function NewProject({ open, setOpen, sections = null, refresh, openCreateSection }) {
    const [formData, setFormData] = useState({
        name: '',
        customer_name: '',
        customer_address: '',
        customer_phone: '',
        install_date: '',
        utility_company: '',
        system_size: '',
        panel_type: '',
        roof_type: '',
        site_improvements: '',
        panel_count: '',
        inverter_type: '',
        scheduled_duration: ''
    });
    const [selectedSections, setSelectedSections] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSectionSelection = (sectionId) => {
        if (selectedSections.includes(sectionId)) {
            setSelectedSections(selectedSections.filter(id => id !== sectionId));
        } else {
            setSelectedSections([...selectedSections, sectionId]);
        }
    };

    const createProject = async () => {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, {
            projectDetails: formData,
            sectionIds: selectedSections
        });
        refresh(response.data.projectId);
        setLoading(false);
        setOpen(false);
        setFormData({
            name: '',
            customer_name: '',
            customer_address: '',
            customer_phone: '',
            install_date: '',
            utility_company: '',
            system_size: '',
            panel_type: '',
            roof_type: '',
            site_improvements: '',
            panel_count: '',
            inverter_type: '',
            scheduled_duration: ''
        });
        setSelectedSections([]);
    };

    const canCreate = formData.name !== '' && selectedSections.length > 0;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-6 pb-6 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                    Create New Project
                                </Dialog.Title>
                                <div className="mt-2">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Project Name</label>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Project Name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-lg"
                                    />
                                </div>
                                <div className="mt-4 grid grid-cols-2 gap-4">
                                    {Object.entries(formData).filter(([key, _]) => key !== 'name').map(([key, value]) => (
                                        <div key={key}>
                                            <label htmlFor={key} className="block text-sm font-medium text-gray-700">{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</label>
                                            <input
                                                id={key}
                                                name={key}
                                                type="text"
                                                placeholder={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                                value={value}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="sectionSelect" className="block text-sm font-medium text-gray-700">Sections</label>
                                    <div className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                        {sections?.map((section) => (
                                            <div key={section.section_id} className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    id={`section-${section.section_id}`}
                                                    checked={selectedSections.includes(section.section_id)}
                                                    onChange={() => handleSectionSelection(section.section_id)}
                                                    className="mr-2"
                                                />
                                                <label htmlFor={`section-${section.section_id}`}>{section.name}</label>
                                            </div>
                                        ))}
                                        {sections?.length === 0 && (
                                            <>
                                                <div className="text-gray-500">No sections available, start by creating one</div>
                                                <div key='new-section' className="mt-2 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="self-start inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-base rounded-md shadow-sm text-white bg-gray-500 hover:bg-gray-700 focus:outline-none"
                                                        onClick={openCreateSection}
                                                    >
                                                        + Create new section
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${canCreate ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-400 cursor-not-allowed'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                        onClick={createProject}
                                        disabled={!canCreate}
                                    >
                                        {loading ? (
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full" role="status">
                                                <div className='rounded-full w-4 h-4 border-2 border-t-0 border-indigo-500'></div>
                                            </div>
                                        ) : (
                                            'Create'
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}